<template>
  <div id="graphBox" class="graphBox">
    <div class="title_withLine" style="display: flex;">
      多方关系示意图
      <div style="flex: 1;"></div>
      <div :class="{maxIcon:!fullscreen, minIcon:fullscreen}" style="width: 20px;cursor: pointer;margin-right: 15px;"
           @click="toggleMax">
      </div>
    </div>
    <div
        style="flex:1;height: 1px;display: flex;background: #FFFFFF;">
      <div id="stgxGraphContainer" style="flex: 1;width: 1px;"></div>
      <div :style="{width:panleWidth+'px'}"
           style="border-left: 1px solid rgba(215, 215, 215, 1);box-sizing:border-box;padding-top: 10px;position: relative;">
        <div :class="{rightArrow:panleShow,leftArrow:!panleShow}" @click="changePanleShow"></div>
        <stGraphPanle @changeEdgeColor="changeEdgeColor" @changeNodeColor="changeNodeColor" @close="drawer=false"
                      @filterEdge="filterEdge" @filterNode="filterNode"></stGraphPanle>
      </div>
    </div>

  </div>
</template>

<script>
import stGraphPanle from "./stGraphPanle";
// import {getBusiRelation, getResAnalysis, getCompanyName} from "@/api/stgx";
import {mapState} from "vuex";

export default {
  name: "stGraph",
  components: {
    stGraphPanle
  },
  props: {
    companies: {
      type: Array,
      required: true
    },
    graphData: {
      type: Object,
      required: true
    },

  },
  data() {
    return {
      graph: null,
      panleShow: true,
      panleWidth: 250,
      fullscreen: false,
    }
  },
  mounted() {
    this.initGraph();
    document.addEventListener('fullscreenchange', this.handleListener)
    document.addEventListener('mozfullscreenchange', this.handleListener)
    document.addEventListener('webkitfullscreenchange', this.handleListener)
    document.addEventListener('msfullscreenchange', this.handleListener)
  },
  destroyed() {
    document.removeEventListener('fullscreenchange', this.handleListener)
    document.removeEventListener('mozfullscreenchange', this.handleListener)
    document.removeEventListener('webkitfullscreenchange', this.handleListener)
    document.removeEventListener('msfullscreenchange', this.handleListener)
  },
  methods: {
    /**
     * 初始化图
     */
    initGraph() {
      const tooltip = new this.$G6.Tooltip({
        offsetX: 10,
        offsetY: 5,
        getContent (e) {
          const outDiv = document.createElement('div')
          outDiv.style.width = '120px'
          outDiv.innerHTML = `
      <div>
       ${e.item.getModel().name}
      </div>`
          return outDiv
        },
        itemTypes: ['node']
      })
      const container = document.getElementById('stgxGraphContainer');
      const grid = new this.$G6.Grid();
      const minimap = new this.$G6.Minimap({
        size: [80, 80]
      });
      this.$nextTick(() => {
        this.graph = new this.$G6.Graph({
          container: "stgxGraphContainer",
          height: container.offsetHeight || 500,
          width: container.offsetWidth,
          fitView: false,
          fitCenter: true,
          plugins: [grid, minimap,tooltip],
          layout: {
            type: 'gForce',
            linkDistance: 180,
            preventOverlap: true
          },
          defaultNode: {
            type: "circle",
            size: 60,
            style: {
              cursor: "pointer"
            },
            labelCfg: {
              style: {
                fill: "#333"
              }
            }
          },
          defaultEdge: {
            type: "quadratic",
            style: {
              stroke: "#8792A0",
              endArrow: {
                path: this.$G6.Arrow.vee(10, 10, 5),
                d: 5
              }
            },
            labelCfg: {
              autoRotate: true,
              style: {
                fontSize: 10,
                fill: "#333"
              }
            }
          },
          modes: {
            default: ['drag-canvas', 'zoom-canvas', 'drag-node', 'activate-relations']
          }
        });
        this.getBusiRelation();
        if (typeof window !== 'undefined') {
          window.onresize = () => {
            if (!this.graph || this.graph.get('destroyed')) return;
            const container = document.getElementById('stgxGraphContainer');
            if (!container) return;
            this.graph.changeSize(container.offsetWidth, container.offsetHeight);
          };
        }
      })

    },
    /**
     * 查询图数据
     */
    async getBusiRelation() {
      let tempData = this.graphData;
      let temp = {
        nodes: this.$_.map(tempData.node, (node) => {
          node.label = this.fittingString(node.name, 84, 12);
          node.stateStyles = {
            active: {
              fill: this.nodeTypeMap[node.nodeType][0].color,
              stroke: this.nodeTypeMap[node.nodeType][0].color
            },
            query: {
              fill: "red",
              stroke: "#000",
              lineWidth: 5

            }
          };
          return node;
        }),
        edges: this.$_.map(tempData.edges, (edge) => {
          edge.edgeType = edge.relaType;
          edge.label = edge.positions?edge.positions:this.edgeTypeMap[edge.edgeType][0].name;
          edge.stateStyles = {
            active: {
              stroke: this.edgeTypeMap[edge.edgeType][0].color,
              lineWidth: 3
            }
          };
          return edge
        })
      };
      this.$G6.Util.processParallelEdges(temp.edges, 55, "quadratic");
      if (!this.graph) {
        return
      }
      this.graph.node((node) => {
        let nodeObj = {
          size: this.nodeTypeMap[node.nodeType][0].size,
          style: {
            fill: this.nodeTypeMap[node.nodeType][0].color,
            stroke: this.nodeTypeMap[node.nodeType][0].strokeColor,
          },
          labelCfg: { // 修改节点label样式
            style: {
              fill: this.nodeTypeMap[node.nodeType][0].labelColor, // 字体颜色
              fontSize: this.nodeTypeMap[node.nodeType][0].fontSize// 字体大小
            }
          }
        }

        return nodeObj
      });
      this.graph.edge((edge) => {
        return {
          style: {
            stroke: this.edgeTypeMap[edge.edgeType][0].color,
          },
          labelCfg: {
            autoRotate: true,
            refX: 10,
            refY: 10
          }
        }
      });
      this.graph.data(temp);
      this.graph.render();
      this.filterItme();
    },
    /**
     *长文本处理
     */
    fittingString(str, maxWidth, fontSize) {
      const ellipsis = '...';
      let currentWidth = 0;
      let res = str;
      const pattern = new RegExp('[\u4E00-\u9FA5]+');
      str.split('').forEach((letter, i) => {
        if (currentWidth > maxWidth) return;
        if (pattern.test(letter)) {
          currentWidth += fontSize;
        } else {
          currentWidth += this.$G6.Util.getLetterWidth(letter, fontSize);
        }
        if (currentWidth > maxWidth) {
          if (str.length >= (2 * i)) {
            res = `${str.substr(0, i)}\n${str.substr(i, i - 3)}${ellipsis} `;
          } else {
            res = `${str.substr(0, i)}\n${str.substr(i)}`
          }
        }
      });
      return res;
    },
    /**
     *根据现有状态过滤 节点和边
     */
    filterItme() {
      let allNodes = this.graph.getNodes();
      this.$_.each(allNodes, (node) => {
        let tempNodeData = node.get('model');
        let tempCfgData = this.nodeTypeMap[tempNodeData.nodeType][0];
        if (!tempCfgData.show && node.isVisible()) {
          this.graph.hideItem(node, true)
        }
        if (this.$_.indexOf(this.companies, tempNodeData.name) != -1) {
          node.setState('query', true)
        } else {
          node.clearStates('query')
        }
      })
      let allEdges = this.graph.getEdges();
      this.$_.each(allEdges, (edge) => {
        let tempEdgeData = edge.get('model');
        let tempCfgData = this.edgeTypeMap[tempEdgeData.edgeType][0];
        if (!tempCfgData.show && edge.isVisible()) {
          this.graph.hideItem(edge, false)
        }
      })
    },
    /**
     * 过滤节点
     */
    filterNode(item) {
      let nodes = this.graph.findAll('node', (node) => {
        return node.get('model').nodeType == item.nodeType;
      });
      this.$_.each(nodes, (node) => {
        if (item.show) {
          this.graph.showItem(node, false)
        } else {
          this.graph.hideItem(node, false)
        }
      })
      let allEdges = this.graph.getEdges();
      this.$_.each(allEdges, (edge) => {
        let tempEdgeData = edge.get('model');
        let tempCfgData = this.edgeTypeMap[tempEdgeData.edgeType][0];
        if (!tempCfgData.show && edge.isVisible()) {
          this.graph.hideItem(edge, false)
        }
      })
    },
    /**
     * 过滤边
     */
    filterEdge(item) {
      let edges = this.graph.findAll('edge', (edge) => {
        return edge.get('model').edgeType == item.edgeType;
      });
      this.$_.each(edges, (edge) => {
        if (item.show) {
          if (edge.getSource().isVisible() && edge.getTarget().isVisible()) {
             this.graph.showItem(edge, false)
          }
        } else {
             this.graph.hideItem(edge, false)
        }
      })
    },
    /**
     * 改变节点颜色
     */
    changeNodeColor(item) {
      let nodes = this.graph.findAll('node', (node) => {
        return node.get('model').nodeType == item.nodeType;
      });
      this.$_.each(nodes, (node) => {
        this.graph.updateItem(node, {
          style: {
            fill: item.color
          }
        }, false)
      })
    },
    /**
     * 改变线条颜色
     */
    changeEdgeColor(item) {
      let edges = this.graph.findAll('edge', (edge) => {
        return edge.get('model').edgeType == item.edgeType;
      });
      this.$_.each(edges, (edge) => {
        this.graph.updateItem(edge, {
          style: {
            stroke: item.color
          }
        }, false)
      })
    },
    /**
     * 调整大小
     */
    changeSize() {
      if (!this.graph || this.graph.get('destroyed')) return;
      const container = document.getElementById('stgxGraphContainer');
      if (!container) return;
      this.graph.changeSize(container.offsetWidth, container.offsetHeight);
    },
    changePanleShow() {
      this.panleShow = !this.panleShow;
      if (this.panleShow) {
        this.panleWidth = 250;
      } else {
        this.panleWidth = 0;
      }
      this.$nextTick(() => {
        this.changeSize()
      })
    },
    toggleMax() {
      let element = document.getElementById("graphBox");
      if (!this.fullscreen) {
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.mozRequestFullScreen) {
          //FireFox
          element.mozRequestFullScreen()
        } else if (element.webkitRequestFullScreen) {
          //Chrome等
          element.webkitRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          //IE11
          element.msRequestFullscreen()
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }

    },
    handleListener() {
      this.fullscreen = !this.fullscreen;
    },
  },
  computed: {
    ...mapState('graph', ['stgxNodeTypeList', 'stgxEdgeTypeList']),
    nodeTypeMap() {
      return this.$_.groupBy(this.stgxNodeTypeList, 'nodeType')
    },
    edgeTypeMap() {
      return this.$_.groupBy(this.stgxEdgeTypeList, 'edgeType')
    }
  },
  watch: {
    graphData: {
      handler() {
        if (this.graph) {
          this.getBusiRelation();
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.graphBox {
  height: 616px;
  display: flex;
  flex-direction: column;
  //padding: 15px 30px;
  box-sizing: border-box;
  background: linear-gradient(180deg, #F4F9FF 0%, #FFFFFF 34.89%);
  //border: 2px solid #FFFFFF;
  /* 卡片头晕 */

  box-shadow: 8px 8px 20px rgba(56, 75, 102, 0.1);
  border-radius: 8px;
z-index:10;
}

#stgxGraphContainer {
  //height: 542px;
  height: 100%;
  position: relative;
}

.rightArrow {
  width: 25px;
  height: 30px;
  background-image: url("~@/assets/image/rightArrow.png");
  background-repeat: no-repeat;
  background-size: 25px 30px;
  position: absolute;
  left: -20px;
  top: calc(50% - 15px);
  cursor: pointer;

}

.leftArrow {
  width: 25px;
  height: 30px;
  background-image: url("~@/assets/image/leftArrow.png");
  background-repeat: no-repeat;
  background-size: 25px 30px;
  position: absolute;
  left: -20px;
  top: calc(50% - 15px);
  cursor: pointer;
}

.maxIcon {
  width: 20px;
  background-image: url("~@/assets/image/max.png");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.minIcon {
  width: 20px;
  background-image: url("~@/assets/image/min.png");
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
}
.title_withLine{
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  box-sizing: border-box;
  position: relative;
  padding-left: 15px;
  box-shadow: inset 0 -1px 0 #e5e6eb;
  &::before{
    content:"";
    display: block;
    width: 5px;
    height: 26px;
    background: #3D7EF7;
    position: absolute;
    left: 5px;
    top: 13px;
  }
}
</style>
