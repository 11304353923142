<template>
  <div style="width: 100%;height: 100%;overflow: auto;padding: 10px 0px;box-sizing: border-box;display: flex;flex-direction:column;">
    <div class="p_title">关系筛选</div>
    <div style="display: flex;justify-content: center;margin: 12px 0">
      <div class="drawerBtn" :class="tabVal==='zt'?'activeDrawerBtn':''" @click="handleDrawerBtn('zt')">主体</div>
      <div class="drawerBtn"  :class="tabVal==='gx'?'activeDrawerBtn':''" @click="handleDrawerBtn('gx')">关系</div>
    </div>

    <!--<div class="p_title">主体筛选</div>-->
    <div v-if="tabVal==='zt'">
      <div v-for="(item,idx) in stgxNodeTypeList" :key="idx"
           style="height: 30px;margin-top: 15px;padding: 0 20px;box-sizing: border-box;display: flex;">
        <el-color-picker
            v-model="item.color"
            :predefine="predefineColors"
            show-alpha
            @change="(color)=> handleNodeColorChange(color,item)"
        >
        </el-color-picker>
        <div style="font-size: 14px;line-height: 30px;margin-left: 8px;">{{ item.name }}</div>
        <div style="flex:1;"></div>
        <el-switch v-model="item.show" @change="(show)=> handleNodeVisibleChange(show,item)">
        </el-switch>
      </div>
    </div>
    <!--<el-divider></el-divider>-->
    <!--<div class="p_title">关系筛选</div>-->
    <div v-if="tabVal==='gx'">
      <div v-for="(item,idx) in stgxEdgeTypeList" :key="idx"
           style="height: 30px;margin-top: 15px;padding: 0 20px;box-sizing: border-box;display: flex;">
        <el-color-picker
            v-model="item.color"
            :predefine="predefineColors"
            show-alpha
            @change="(color)=> handleEdgeColorChange(color,item)"
        >
        </el-color-picker>
        <div style="font-size:14px;line-height: 30px;margin-left: 8px;">{{ item.name }}</div>
        <div style="flex:1;"></div>
        <el-switch v-model="item.show" @change="(show)=> handleEdgeVisibleChange(show,item)">
        </el-switch>
      </div>
    </div>
<!--    <el-divider></el-divider>-->
<!--    <div class="p_title">层级关系</div>-->
<!--    <div style="margin-top:10px;padding: 0 20px;">-->
<!--      <el-slider v-model="Apinums" @change="changeApiNums" :min="1" :max="9" style="width: 200px;"></el-slider>-->
<!--    </div>-->
<!--    <el-divider></el-divider>-->
<!--    <div class="p_title">查询方向</div>-->
<!--    <div style="margin-top: 20px;padding: 0 20px;">-->
<!--      <el-radio-group v-model="query_target" @change="changeTarget">-->
<!--        <el-radio :label="1">同时向下和向下</el-radio>-->
<!--        <el-radio :label="2" style="margin-top: 10px;">仅向上查询(股权)</el-radio>-->
<!--        <el-radio :label="3" style="margin-top: 10px;">仅向下查询(投资)</el-radio>-->
<!--      </el-radio-group>-->
<!--    </div>-->
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "stGraphPanle",
  data() {
    return {
      tabVal:'zt',
      currentType: "node",
      Apinums:3,
      query_target:1,
      predefineColors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ]
    }
  },
  mounted() {
    // this.Apinums = this.stgxApinums;
    // this.query_target = this.stgxQueryTarget;
  },
  methods: {
    /**
     * 关闭
     */
    close() {
      this.$emit('close')
    },
    /**
     * 节点颜色改变事件
     */
    handleNodeColorChange(color, item) {
      this.setStgxNodeTypeList(this.stgxNodeTypeList);
      this.$emit('changeNodeColor', item)
    },
    /**
     * 节点状态开关变化
     */
    handleNodeVisibleChange(show, item) {
      this.setStgxNodeTypeList(this.stgxNodeTypeList);
      this.$emit('filterNode', item)
    },
    /**
     * 连线颜色改变事件
     */
    handleEdgeColorChange(color, item) {
      this.setStgxEdgeTypeList(this.stgxEdgeTypeList)
      this.$emit('changeEdgeColor', item)
    },
    /**
     * 连线状态开关变化
     */
    handleEdgeVisibleChange(show, item) {
      this.setStgxEdgeTypeList(this.stgxEdgeTypeList)
      this.$emit('filterEdge', item)
    },
    // changeApiNums(val){
    //   this.Apinums = val;
    //   this.setApiNums(val)
    // },
    // changeTarget(val){
    //   this.query_target = val;
    //   this.setQueryTarget(val);
    // },
    handleDrawerBtn(val){
      this.tabVal = val
    },

    ...mapMutations("graph", ['setStgxEdgeTypeList', 'setStgxNodeTypeList','setApiNums','setQueryTarget'])
  },
  computed: {
    ...mapState("graph", ['stgxNodeTypeList', 'stgxEdgeTypeList','stgxApinums','stgxQueryTarget']),
  }
}
</script>
<style lang="scss" scoped>
.drawerBtn {
  height: 25px;
  width: 100px;
  line-height: 25px;
  text-align: center;
  border: 1px solid rgba(215, 215, 215, 1);
  font-size: 14px;
  cursor: pointer;
}

.activeDrawerBtn {
  color: #fff;
  background-color: #3973ff;
}
.p_title{
  padding-left: 20px;
  font-weight: bold;
}
</style>
